/*anchor*/
a {
    color: #32323A;
}

a:hover {
    color: #222227;
}

/*panel*/
.panel {
    border: none;
    box-shadow: none;
}

.panel-heading {
    border-color:#eff2f7 ;
    font-size: 13px;
    font-weight: 400;
    background: #fafafa;
    text-transform: uppercase;
    padding: 15px;
}

.panel-title {
    color: #32323A;
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 0;
    margin-top: 0;
    font-family: Lato, Helvetica, Arial, sans-serif;
}

.panel-primary .tools a {
    color: #fff;
}


/*label*/

.label {
    padding: 0.5em 0.8em;
}

.label-default {
    background-color: #a1a1a1;
}

.label-primary {
    background-color: #59ace2;
}

.label-success {
    background-color: #83a243;
}

.label-info {
    background-color: #915682;
}

.label-warning {
    background-color: #fdbf53;
}

.label-danger {
    background-color: #ad3b4a;
}

.label-inverse {
    background-color: #344860;
}

/*text color*/

.text-danger {
    color: #ad3b4a;
}

.text-muted {
    color: #a1a1a1;
}

.text-primary {
    color: #59ace2;
}

.text-warning {
    color: #fdbf53;
}

.text-success {
    color: #83a243;
}

.text-info {
    color: #915682;
}

/*modal*/

.modal-content {
    box-shadow: none;
    border: none;
}

.modal-header {
    background: #FAFAFA;
    color: #333;
    border-radius: 5px 5px 0 0;
    -webkit-border-radius: 5px 5px 0 0;
    /*border-bottom: none;*/
}

.modal-header .close {
    margin-top: 0;
}

/*text input*/

.form-control {
    border: 1px solid #e2e2e4;
    box-shadow: none;
    color: #767676;
}


.form-control:focus, #focusedInput {
    border: 1px solid #00A8B3;
    box-shadow: none;
}

.form-horizontal .control-label {
    font-weight: 300;
    font-size: 14px;
}

input, textarea, select, button {
    outline: none !important;
}

/*list*/

ul {
    padding-left: 0;
}

/*button*/
.btn {
    background-color: #818181;
    color: white;
}
.btn:hover, .btn:focus, .btn:active, .btn.active {
    color: white;
}
.btn-link {
    background-color: transparent;
    color: #5db9d1;
}
.btn-link:hover, .btn-link:focus, .btn-link:active, .btn-link.active {
    color: #5db9d1;
}

.btn-default {
    background-color: #c7cbd6;
    border-color: #c7cbd6;
    color: #fff;
}

.btn-default:hover, .btn-default:focus, .btn-default:active, .btn-default.active, .open .dropdown-toggle.btn-default {
    background-color: #b0b5b9;
    border-color: #b0b5b9;
    color: #fff;
}

.btn-primary {
    background-color: #5db9d1;
    border-color: #5db9d1;
    color: #FFFFFF;
}

.btn-primary:hover, .btn-primary:focus, .btn-primary:active, .btn-primary.active, .open .dropdown-toggle.btn-primary {
    background-color: #7fcee1;
    border-color: #7fcee1;
    color: #FFFFFF;
}

.btn-success {
    background-color: #83a243;
    border-color: #83a243;
    color: #FFFFFF;
}

.btn-success:hover, .btn-success:focus, .btn-success:active, .btn-success.active, .open .dropdown-toggle.btn-success {
    background-color: #b3c375;
    border-color: #b3c375;
    color: #FFFFFF;
}

.btn-info {
    background-color: #915682;
    border-color: #915682;
    color: #FFFFFF;
}

.btn-info:hover, .btn-info:focus, .btn-info:active, .btn-info.active, .open .dropdown-toggle.btn-info {
    background-color: #bb8cad;
    border-color: #bb8cad;
    color: #FFFFFF;
}

.btn-warning {
    background-color: #fdbf53;
    border-color: #fdbf53;
    color: #FFFFFF;
}

.btn-warning:hover, .btn-warning:focus, .btn-warning:active, .btn-warning.active, .open .dropdown-toggle.btn-warning {
    background-color: #fbd797;
    border-color: #fbd797;
    color: #FFFFFF;
}

.btn-danger {
    background-color: #ad3b4a;
    border-color: #ad3b4a;
    color: #FFFFFF;
}

.btn-danger:hover, .btn-danger:focus, .btn-danger:active, .btn-danger.active, .open .dropdown-toggle.btn-danger {
    background-color: #d57f8e;
    border-color: #d57f8e;
    color: #FFFFFF;
}


.btn-white {
    box-shadow: none !important;
}

/*Rounded Button*/

.btn-round {
    border-radius: 30px;
    -webkit-border-radius: 30px;
}


/*dropdown shadow*/

.btn-group.open .dropdown-toggle, .btn-white.active, .btn:active, .btn.active {
    box-shadow: none;
}

/*dropdown select bg*/
.dropdown-menu > li > a:hover, .dropdown-menu > li > a:focus {
    background-color: #474752;
    color: #FFFFFF;
    text-decoration: none;
}

/*split dropdown btn*/

.btn-white {
    background-clip: padding-box;
    background-color: #FFFFFF;
    border-color: rgba(150, 160, 180, 0.3);
    box-shadow: 0 -1px 1px rgba(0, 0, 0, 0.05) inset;
}

/*breadcrumbs*/

.breadcrumb {
    background-color: #fff;
}


/*tab*/

.nav-tabs > li > a {
    margin-right: 1px;
}

/*collapse*/

.panel-default > .panel-heading {
    background-color: #FFFFFF;
    border-color: #DDDDDD;
    color: #797979;
}

/*nav inverse*/

.navbar-inverse {
    background-color: #32323A;
    border-color: #32323A;
}

.navbar-inverse .navbar-nav > .active > a, .navbar-inverse .navbar-nav > .active > a:hover, .navbar-inverse .navbar-nav > .active > a:focus,
.navbar-inverse .navbar-nav > .open > a, .navbar-inverse .navbar-nav > .open > a:focus{
    background-color: #474752;
}

.navbar-inverse .navbar-nav > li a:hover {
    color: #fff;
}

.navbar-inverse .navbar-nav > li > ul > li a:hover {
    color: #fff;
}

.navbar-inverse .navbar-brand {
    color: #FFFFFF;
}

.navbar-inverse .navbar-nav > li > a {
    color: #fff;
}

.navbar-inverse .navbar-nav > .dropdown > a .caret {
    border-bottom-color: #fff;
    border-top-color: #fff;
}

.navbar-inverse .navbar-nav .open .dropdown-menu > li > a {
    color: #000;
}
.navbar-inverse .navbar-nav .open .dropdown-menu > li > a:hover {
    color: #fff;
}

/*nav justified*/

.nav-justified {
    width: auto !important;
}

.nav-justified li:last-child > a:hover, .nav-justified li.active:last-child > a {
    border-radius: 0 4px 0 0 !important;
    -webkit-border-radius: 0 4px 0 0 !important;
}

/*list group*/

.list-group-item.active, .list-group-item.active:hover, .list-group-item.active:focus {
    background-color: #00A8B3;
    border-color: #00A8B3;
    color: #FFFFFF;
    z-index: 2;
}

.list-group-item-heading {
    font-weight: 300;
}

/*progress*/

.progress {
    box-shadow: none;
    background: #f0f2f7;
}

/*alert*/

.alert-success, .alert-danger, .alert-info, .alert-warning {
    border: none;
}

/*table*/

.table thead > tr > th, .table tbody > tr > th, .table tfoot > tr > th, .table thead > tr > td, .table tbody > tr > td, .table tfoot > tr > td {
    padding: 10px;
}

/*pagination*/

.pagination > li > a, .pagination > li > span {
    background-color: #EFF2F7;
    border: 1px solid #EFF2F7;
    float: left;
    line-height: 1.42857;
    margin-left: 1px;
    padding: 6px 12px;
    position: relative;
    text-decoration: none;
}

.pagination > li > a:hover, .pagination > li > span:hover, .pagination > li > a:focus, .pagination > li > span:focus {
    background-color: #2eb4ad;
    color: #fff;
}


/*--*/

.has-success .form-control:focus, .has-error .form-control:focus, .has-warning .form-control:focus {
    box-shadow: none;
}

.panel-group .panel + .panel {
    margin-top: 1px;
}

.progress {
    border-radius: 50px;
    -webkit-border-radius: 50px;
}

.progress-bar {
    box-shadow: none;
}
