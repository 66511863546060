/* ========================================================================
 * bootstrap-switch - v2.0.0
 * http://www.bootstrap-switch.org
 * ========================================================================
 * Copyright 2012-2013 Mattia Larentis
 *
 * ========================================================================
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 * ========================================================================
 */

.has-switch {
  display: inline-block;
  cursor: pointer;
  border-radius: 50px;
  border: 1px solid;
  border-color: #cccccc;
  position: relative;
  text-align: left;
  overflow: hidden;
  line-height: 8px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
  vertical-align: middle;
  min-width: 100px;
  -webkit-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
  transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
}
.has-switch:focus {
  /*border-color: #66afe9;*/
  outline: 0;
  /*-webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(102, 175, 233, 0.6);*/
  /*box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(102, 175, 233, 0.6);*/
}
.has-switch.switch-mini {
  min-width: 72px;
}
.has-switch.switch-mini i.switch-mini-icons {
  height: 1.20em;
  line-height: 9px;
  vertical-align: text-top;
  text-align: center;
  transform: scale(0.6);
  margin-top: -1px;
  margin-bottom: -1px;
}
.has-switch.switch-small {
  min-width: 80px;
}
.has-switch.switch-large {
  min-width: 120px;
}
.has-switch.disabled {
  opacity: 0.5;
  filter: alpha(opacity=50);
  cursor: default !important;
}
.has-switch.disabled label,
.has-switch.disabled span {
  cursor: default !important;
}
.has-switch > div {
  display: inline-block;
  width: 150%;
  position: relative;
  top: 0;
}
.has-switch > div.switch-animate {
  -webkit-transition: left 0.5s;
  transition: left 0.5s;
}
.has-switch > div.switch-off {
  left: -50%;
}
.has-switch > div.switch-on {
  left: 0%;
}
.has-switch input[type=radio],
.has-switch input[type=checkbox] {
  display: none;
}
.has-switch span,
.has-switch label {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  cursor: pointer;
  position: relative;
  display: inline-block !important;
  height: 100%;
  padding-bottom: 4px;
  padding-top: 4px;
  font-size: 14px;
  line-height: 20px;
}
.has-switch span.switch-mini,
.has-switch label.switch-mini {
  padding-bottom: 4px;
  padding-top: 4px;
  font-size: 10px;
  line-height: 9px;
}
.has-switch span.switch-small,
.has-switch label.switch-small {
  padding-bottom: 3px;
  padding-top: 3px;
  font-size: 12px;
  line-height: 18px;
}
.has-switch span.switch-large,
.has-switch label.switch-large {
  padding-bottom: 9px;
  padding-top: 9px;
  font-size: 16px;
  line-height: normal;
}
.has-switch label {
  text-align: center;
  margin-top: -1px;
  margin-bottom: -1px;
  z-index: 100;
  width: 34%;
  background: #ffffff;
}
.has-switch label i {
  color: #000;
  text-shadow: 0 1px 0 #fff;
  line-height: 18px;
  pointer-events: none;
}
.has-switch span {
  text-align: center;
  z-index: 1;
  width: 33%;
}

.has-switch span.switch-left {
  color: #f00;
  border-bottom-left-radius: 4px;
  border-top-left-radius: 4px;
}
.has-switch span.switch-right {
  color: #000;
  background: #eeeeee;
}
.has-switch span.switch-primary,
.has-switch span.switch-left {
  color: #fff;
  background: #428bca;
}
.has-switch span.switch-info {
  color: #fff;
  background: #5bc0de;
}
.has-switch span.switch-success {
  color: #fff;
  background: #5cb85c;
}
.has-switch span.switch-warning {
  background: #f0ad4e;
  color: #fff;
}
.has-switch span.switch-danger {
  color: #fff;
  background: #d9534f;
}
.has-switch span.switch-default {
  color: #000;
  background: #eeeeee;
}
