
.webviewer {
	border:solid 1px #555;
	margin:0px auto 10px auto;
}

.webviewer-wrapper {
	margin-top:50px;
}

.webviewer input[type="text"] {
	border:none;
	background:none;
}

.webviewer label {
	text-align:center;
	line-height:100%;
}

.webviewer input[type='radio']:checked + label, .webviewer input[type="checkbox"]:checked + label {
	background:black;
	border-radius:50%;
}

.webviewer.editing .field {
	background:#555;
	opacity:0.5;
	overflow:hidden;
	box-sizing:border-box;
	padding:0px 5px;
	color:white;
	font-family:"Inconsolata", "Courier New", monospace;
}

.webviewer.editing .field:hover {
	outline:dashed 1px black;
	cursor:move;
}

.webviewer.editing .field.selected, .webviewer.editing .field.ui-selected {
	background:blue;
}

.kbw-signature {
	display: inline-block;
	width:100%;
	height:80px;
	border: 1px solid #a0a0a0;
}

.ui-selectable-helper {
	z-index:99999;
}

#arrowWrapper {
	margin		     : 0 10px 5px 10px;
	height		 	 : 60px;
	/*background-color : #e7e7e7; */
	background-color : #F7FDFF;
	border 		 	 : 1px solid #F7FDFF;
	border-radius    : 3px;
	line-height	 	 : 60px;	
}

#arrowWrapper a {
	background-color: #f8f8f8;
	height: 100%;
	font-size: 24px;
	font-weight: bold;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

#arrowWrapper a:hover {
/*	background-color: #F7FDFF;*/
	background-color: white;
	text-decoration: none;
	cursor: pointer;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

#arrowWrapper.col-sm-12 {
	border-radius: 4px;
}


#arrowWrapper i:before {
	width: 40px; 
}

#arrowWrapper .last-button {
	border-top-left-radius: 4px;
	border-bottom-left-radius: 4px;
}
#arrowWrapper .next-button {
	border-top-right-radius: 4px;
	border-bottom-right-radius: 4px;
}

